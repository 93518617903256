<!--
 * @Descripttion: 视频播放器
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月24日11:13:53
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月24日11:21:1
 -->
<template>
  <div class="preview" v-show="layer.show">
    <div class="mask"></div>
  </div>
</template>

<script>
  export default {
    components: {

    },
    props: {
      'layer': {
        type: Object,
        default: () => {
          return {
            show: false,
            active: {}
          }
        }
      }
    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  .preview{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      width: 100vw;
      height: 100vh;
      opacity: 0.8;
      z-index: -1;
    }
  }
</style>
